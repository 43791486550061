// Lib
import React from 'react'
import { withFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { useSearchParams } from 'react-router-dom'

// Include in project
import styles from './index.module.scss'
import Layout from 'layouts'
import { EResCode } from 'utils/other'
import { E_Is_Active, Inventory, E_Trigger_Type } from 'utils/generated'
import { menuHeaderInventory } from 'utils/menu'
import { setCloseDialog, setOpenDialog } from 'states/slice/modalDialog'
import { useInfiniteScroll, useLoadingControl, useTransection } from 'hooks'
import { Table, Button, TextField, Notification } from 'component'
import { ETranformationDataIntoTable, transformationDataIntoTable } from 'utils/transformationDataIntoTable'
import { pageSize } from 'configs'

// Images

export interface ITransection {
  filter: {
    search: string
  }
}

const intitialValue: ITransection = {
  filter: {
    search: '',
  },
}

const Transection: React.FC = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [searchParams] = useSearchParams()
  const productSKUID = searchParams?.get('productSKUID') || null

  const { data, isLoading, refetch, voidTransection } = useTransection({
    filter: {
      productSKUID: productSKUID,
    },
    pageSize: pageSize,
    nextToken: null,
  })

  const { dataList } = useInfiniteScroll({
    result: data,
    refetch: refetch,
    variables: {
      filter: {
        productSKUID: productSKUID,
      },
      pageSize: pageSize,
      nextToken: data?.nextToken || null,
    },
    filter: [null],
    key: 'transactionList',
  })

  useLoadingControl(isLoading)

  const handleVoid = async (data: Inventory) => {
    if (!data.productSKUID || !data.transactionNo || data.isVoided === E_Is_Active.True) return
    if (
      data.triggerType !== E_Trigger_Type.GoodsReceipt &&
      data.triggerType !== E_Trigger_Type.GoodsIssue &&
      data.triggerType !== E_Trigger_Type.InventoryAdjustment
    )
      return

    const packData = {
      input: {
        productSKUID: data.productSKUID,
        transactionNo: data.transactionNo,
      },
    }
    voidTransection({ variables: packData }).then((res) => {
      const resVoid = res.data.voidTransaction
      if (resVoid.res_code !== EResCode.Success) return

      refetch()
      enqueueSnackbar(<Notification title="Success" description="Void Transection." />, { variant: 'success' })
      dispatch(setCloseDialog())
    })
  }

  const dataToTable = transformationDataIntoTable({
    dataList: dataList || [],
    type: ETranformationDataIntoTable.TransectionList,
    handleArchive: (data) => handleVoid(data),
  })

  return (
    <Layout headerSectionName="Inventory Management" headerSectionManu={menuHeaderInventory}>
      <div className={styles.container}>
        <div className={styles.wrapperButton}>
          <div className={styles.box}>
            {/* <TextField label="" placeholder="Search" type="text" name="filter.search" dataAdornment={Search} />
            <Button typeButton="button" type="info" size="small" name="Search" /> */}
          </div>
          {/* <Button typeButton="button" type="gray" size="small" name="Export" startIcon={<IosShare />} /> */}
        </div>

        <Table
          objectKey={{
            createdAt: 'Created At',
            orderNoHTML: 'Order Number',
            productSKU: 'Sku',
            productSKUName: 'Sku Name',
            stockAccumulatedHTML: 'In Stock',
            stockCommittedHTML: 'Committed',
            stockIncomingHTML: 'Incoming',
            stockAvailableHTML: 'Available',
            costPerPcsHTML: 'Cost Per Pcs.',
            stockValueHTML: 'Stock Value (฿)',
            statusHTML: 'Status',
            action: '',
          }}
          data={dataToTable}
        />
      </div>
    </Layout>
  )
}

const EnhancedTransection = withFormik({
  mapPropsToValues: () => ({
    ...intitialValue,
  }),
  validateOnMount: true,
  handleSubmit: () => {
    console.log('Skip')
  },
})(Transection)

export default EnhancedTransection
