import { gql } from '@apollo/client'

export const TRANSECTION_LIST = gql`
  query transactionList($filter: InventoryFilter!, $pageSize: Int, $nextToken: String) {
    transactionList(filter: $filter, pageSize: $pageSize, nextToken: $nextToken) {
      res_code
      res_desc
      nextToken
      items {
        createdAt
        productSKUID
        transactionNo
        companyID
        productID
        orderID
        qty
        triggerType
        stockIncoming
        stockCommitted
        stockAvailable
        stockAccumulated
        customAttribute
        order {
          orderNo
        }
        productSKU {
          productSKUName
          productSKUID
          productSKU
          pricing {
            res_code
            res_desc
            productSKUID
            pricingID
            pricingModel
            priceModelStandard {
              paymentType
              defaultPrice
              salePrice
            }
            priceModelVolumn {
              paymentType
              tableVolumnPrice {
                startUnit
                endUnit
                salePrice
              }
            }
            cost
            currency
          }
        }
        isVoided
      }
    }
  }
`

export const CREATE_TRANSECTION = gql`
  mutation Mutation($input: CreateTransaction!) {
    createTransaction(input: $input) {
      res_code
      res_desc
      productSKUID
      transactionNo
      companyID
      productID
      orderID
      qty
      triggerType
      stockIncoming
      stockCommitted
      stockAvailable
      stockAccumulated
      customAttribute
      isVoided
      createdAt
      updatedAt
    }
  }
`

export const VOID_TRANSECTION = gql`
  mutation Mutation($input: VoidTransaction!) {
    voidTransaction(input: $input) {
      res_code
      res_desc
      productSKUID
      transactionNo
      companyID
      productID
      orderID
      qty
      triggerType
      stockIncoming
      stockCommitted
      stockAvailable
      stockAccumulated
      customAttribute
      isVoided
      createdAt
      updatedAt
    }
  }
`
